import { Outlet, useMatches } from "@remix-run/react"
import { useMemo } from "react"
import { twMerge } from "tailwind-merge"

import SeparatorDot from "../designComponents/SeparatorDot"

interface MatchDataType {
  pageTitle: string
}

function isMatchDataType(data: unknown): data is MatchDataType {
  return !!data && typeof data === "object" && "pageTitle" in data
}

export interface MatchHandleType {
  isFullWidth: boolean
}

function isMatchHandleType(handle: unknown): handle is MatchHandleType {
  return !!handle && typeof handle === "object" && "isFullWidth" in handle
}

export default function App() {
  const matches = useMatches()

  const matchData = useMemo(
    () => matches.map((match) => match.data).find((data) => isMatchDataType(data)),
    [matches]
  ) as MatchDataType | undefined

  const pageTitle = useMemo(() => (matchData ? matchData["pageTitle"] : undefined), [matchData])

  const matchHandle = useMemo(
    () => matches.map((match) => match.handle).find((handle) => isMatchHandleType(handle)),
    [matches]
  ) as MatchHandleType | undefined

  const isFullWidth = useMemo(
    () => (matchHandle ? matchHandle["isFullWidth"] : false),
    [matchHandle]
  )

  return (
    <>
      <header className="flex items-center justify-start border-b px-global-side-padding-small py-3 md:px-global-side-padding">
        <div className="flex flex-row items-center gap-x-2.5">
          <img
            alt="Bendigo Bank Logo"
            className="hidden h-6 md:block"
            src="/images/branding/bendigo-bank-logo.png"
          />
          <img
            alt="Bendigo Bank Logo"
            className="block h-4 md:hidden"
            src="/images/branding/bendigo-bank-logomark.png"
          />

          <SeparatorDot />

          <h1 className="flex-1 pb-0.5 text-base md:text-lg">
            {pageTitle || "Community Building Platform"}
          </h1>
        </div>
      </header>

      <main
        className={twMerge(
          "flex flex-1 flex-col items-center",
          isFullWidth ? null : "px-global-side-padding-small md:px-global-side-padding"
        )}
      >
        <Outlet />
      </main>
    </>
  )
}
